// Third party logos
import Cargowise from '/public/third-party-logos/Cargowise.png';
import Sap from '/public/third-party-logos/Sap.png';
import Oracle from '/public/third-party-logos/Oracle.png';
import Compcare from '/public/third-party-logos/Compcare.png';
import BlueYonder from '/public/third-party-logos/BlueYonder.png';
import Descartes from '/public/third-party-logos/Descartes.png';
import Magaya from '/public/third-party-logos/Magaya.png';
import MicrosoftDynamics from '/public/third-party-logos/MicrosoftDynamics.png';

export const THIRD_PARTIES = [
  {
    id: 'cargowise',
    logoSrc: Cargowise,
  },
  {
    id: 'sap',
    logoSrc: Sap,
  },
  {
    id: 'oracle',
    logoSrc: Oracle,
  },
  {
    id: 'compcare',
    logoSrc: Compcare,
  },
  {
    id: 'blueYonder',
    logoSrc: BlueYonder,
  },
  {
    id: 'descartes',
    logoSrc: Descartes,
  },
  {
    id: 'magaya',
    logoSrc: Magaya,
  },
  {
    id: 'microsoftDynamics',
    logoSrc: MicrosoftDynamics,
  },
];
