/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

// import AppIcon from '@assets/customizedIconSet/AppIcon.svg';
import styled from '@emotion/styled';
import { Input } from 'antd';
import kebabCase from 'lodash/kebabCase';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { IoClose, IoSearch } from 'react-icons/io5';

import AccessUnavailable from '@assets/customizedIconSet/allApps/AccessUnavailable.svg';
import ActivityRecognitionIcon from '@assets/customizedIconSet/allApps/ActivityRecognition.svg';
import ALPRIcon from '@assets/customizedIconSet/allApps/ALPR.svg';
import AnalyticsIcon from '@assets/customizedIconSet/allApps/Analytics.svg';
import AssetTrackingIcon from '@assets/customizedIconSet/allApps/AssetTracking.svg';
import CartAuditIcon from '@assets/customizedIconSet/allApps/CartAudit.svg';
import ContainerOCRIcon from '@assets/customizedIconSet/allApps/ContainerOCR.svg';
import ConveyorBeltScanIcon from '@assets/customizedIconSet/allApps/ConveyorBeltScan.svg';
import DamageDetectionIcon from '@assets/customizedIconSet/allApps/DamageDetection.svg';
import DeliveryOrderIcon from '@assets/customizedIconSet/allApps/DeliveryOrder.svg';
import DockDoorPlanningIcon from '@assets/customizedIconSet/allApps/DockDoorPlanning.svg';
import DocumentDigitalizationIcon from '@assets/customizedIconSet/allApps/DocumentDigitalization.svg';
import EPODIcon from '@assets/customizedIconSet/allApps/EPOD.svg';
import FreightForwardingIcon from '@assets/customizedIconSet/allApps/FreightForwarding.svg';
import GIGOIcon from '@assets/customizedIconSet/allApps/GIGO.svg';
import IndustrialAutomationIcon from '@assets/customizedIconSet/allApps/IndustrialAutomation.svg';
import IndustrialOmniverseIcon from '@assets/customizedIconSet/allApps/IndustrialOmniverse.svg';
import InsightsIcon from '@assets/customizedIconSet/allApps/Insights.svg';
import InventoryOptimizationIcon from '@assets/customizedIconSet/allApps/InventoryOptimization.svg';
import ItemDimensioningIcon from '@assets/customizedIconSet/allApps/ItemDimensioning.svg';
import KoiVision from '@assets/customizedIconSet/allApps/KoiVision.svg';
import LabelScanIcon from '@assets/customizedIconSet/allApps/LabelScan.svg';
import OmniverseIcon from '@assets/customizedIconSet/allApps/Omniverse.svg';
import PortSafetySurveillanceIcon from '@assets/customizedIconSet/allApps/PortSafetySurveillance.svg';
import QRBarcodeScanIcon from '@assets/customizedIconSet/allApps/QRBarcode.svg';
import RealWearIcon from '@assets/customizedIconSet/allApps/Realwear.svg';
import SKURecognitionIcon from '@assets/customizedIconSet/allApps/SKURecognition.svg';
import SmartPortsIcon from '@assets/customizedIconSet/allApps/SmartPorts.svg';
import SmartWarehousingIcon from '@assets/customizedIconSet/allApps/SmartWarehousing.svg';
import SmartYardsIcon from '@assets/customizedIconSet/allApps/SmartYards.svg';
import TeamViewerIcon from '@assets/customizedIconSet/allApps/TeamViewer.svg';
import ThirdPartyAppsIcon from '@assets/customizedIconSet/allApps/ThirdPartyApps.svg';
import VisionPickingIcon from '@assets/customizedIconSet/allApps/VisionPicking.svg';
import WarehouseSafetySurveillanceIcon from '@assets/customizedIconSet/allApps/WarehouseSafetySurveillance.svg';
import WaveBackground from '@assets/customizedIconSet/allApps/WaveBackground.svg';
import YardCheckIcon from '@assets/customizedIconSet/allApps/YardCheck.svg';
import HomeIcon from '@assets/customizedIconSet/Home.svg';
import KoiReaderEmblem from '@assets/KoiReaderEmblem.svg';
import { homePageFiltersVar } from '@common/apollo/reactiveVars';
import { initialFilters } from '@common/components/home/utils';
import Empty from '@components/elements/Empty';
import PageHeader from '@components/elements/PageHeader';
import AppLayout from '@components/layouts/appLayout';
import { useModal } from '@hooks';
import { subscribedDashboardAppsVar } from 'src/apollo/reactiveVars';
import {
  AppGroupNames,
  AppNames,
  KOIREADER_SUPPORT_EMAIL,
} from 'src/global/constants';
import { THIRD_PARTIES } from 'src/global/constants/thirdParties';
import { WickedApi } from 'src/global/types';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: 1.25rem;
  background: ${(props) => props.theme.color.primary600};
`;

const StyledPage = styled.section`
  height: calc(100vh - 62px);
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 0.1fr auto 0.1fr;
  position: relative;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
  }

  .background {
    position: absolute;
    width: 50%;
    height: auto;
    opacity: 1;
    top: 0;
    right: 0;
    pointer-events: none;
  }
`;

const AllAppsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: auto;
  height: 100%;
  padding: 2rem;
  padding-top: 0;
`;

const StyledAppsSection = styled.section`
  .heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.7rem;
    margin-bottom: 1rem;

    svg {
      width: 3.5rem;
    }
    h1 {
      font-size: 1em;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      margin: 0;
    }
  }
  .apps {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
  }
`;

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.theme.color.gray500};
  background: ${(props) => props.theme.color.primary600};
  height: 10rem;
  aspect-ratio: 1;
  padding: 1.5rem;
  text-align: center;
  line-height: 1.2;
  border: 2px solid ${(props) => props.theme.color.primary300};
  transition: 300ms ease;

  &:hover {
    background: ${(props) => props.theme.color.primary300};
  }

  svg {
    width: 4rem;
  }

  span {
    font-size: 1em;
    font-weight: 600;
  }
`;

const StyledTitle = styled.h2`
  font-size: 1.4em;
  margin: 0;
`;

const StyledSearchInput = styled(Input)`
  width: 40%;
  max-width: 40rem;
  height: 2.5em;
  color: ${(props) => props.theme.color.gray800};
  background: ${(props) => props.theme.color.primary200};
  border: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .ant-input {
    background: ${(props) => props.theme.home.inputBg};
    color: ${(props) => props.theme.color.gray800};
    border: none;
    height: 100%;
    font-size: 1.1em;
    padding: 0.5em 1em;
    padding-bottom: 0.4em;
    box-shadow: none;
  }

  .ant-input-suffix {
    cursor: pointer;
  }
  .ant-input-group-addon {
    background: ${(props) => props.theme.home.inputBg};
    .ant-input-search-button {
      background: ${(props) => props.theme.home.inputBg};
      border: none;
      height: 100%;
      font-size: 1.1em;
      padding-right: 0.5em;
      box-shadow: none;
      .anticon-search {
        color: ${(props) => props.theme.color.gray800};
      }
    }
  }
`;

const StyledFooter = styled.div`
  width: 100%;
  margin-top: auto;
  background: ${(props) => props.theme.color.primary200};

  .title {
    position: relative;
    background: ${(props) => props.theme.color.primary800};
    padding: 0.7rem 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    width: 60%;
    z-index: 0;

    span {
      font-size: 1.1em;
    }

    .icon {
      display: flex;
      align-items: center;
      svg {
        height: 3rem;
      }
    }

    .accent-design {
      position: absolute;
      z-index: -1;
      width: calc(100% + 3rem);
      height: 100%;
      top: 0;
      left: 0;
      transform: skew(-50deg);
      background: inherit;
      border-right: 2px solid ${(props) => props.theme.color.accent700};
      border-bottom: 1px solid ${(props) => props.theme.color.accent700};

      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        right: -8rem;
        width: 8rem;
        height: 1px;
        color: ${(props) => props.theme.color.accent700};
        background: currentColor;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: -0.45rem;
        right: -9rem;
        height: 1rem;
        width: 1rem;
        transform: skew(50deg);
        border-radius: 50%;
        border: 1px solid ${(props) => props.theme.color.accent700};
      }
    }
  }

  .third-parties {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    width: min(75%, 1200px);
    padding: 1rem 3rem;
  }
`;

interface AppDetails {
  id: WickedApi;
  name: AppNames;
  icon: JSX.Element;
  isSubscribed: boolean;
}
interface AppGroup {
  id: string;
  title: AppGroupNames;
  icon: JSX.Element;
  appsDetails: AppDetails[];
}
interface AllAppsProps {
  appGroups: AppGroup[];
}

// All apps container component
const AllApps = ({ appGroups }: AllAppsProps) => {
  const { t: common } = useTranslation();
  const [searchValue, setSearchValue] = React.useState('');
  const { modal } = useModal();

  const isHomeTab = appGroups.length > 1;

  // Reset search value when app group changes
  React.useEffect(() => {
    setSearchValue('');
  }, [appGroups]);

  const filteredAppGroups = appGroups.filter((group) =>
    group.appsDetails.some((app) =>
      app.name.toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  return (
    <HomeContainer>
      <PageHeader icon={<HomeIcon />} title="Apps" titleLink={'/'} />
      <StyledPage>
        <WaveBackground className="background" />
        <div className="wrapper">
          <StyledTitle>
            {isHomeTab ? 'All Apps' : appGroups[0].title}
          </StyledTitle>
          <StyledSearchInput
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            suffix={
              searchValue.length > 0 ? (
                <IoClose onClick={() => setSearchValue('')} />
              ) : (
                <IoSearch />
              )
            }
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
          />
        </div>
        <AllAppsContainer>
          {filteredAppGroups.length > 0 ? (
            filteredAppGroups.map((group) => {
              return (
                <StyledAppsSection key={group.title}>
                  {isHomeTab && (
                    <div className="heading">
                      {group.icon}
                      <h1>{group.title}</h1>
                    </div>
                  )}
                  <div className="apps">
                    {group.appsDetails
                      .filter((app) =>
                        app.name
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      )
                      .map((app) =>
                        app.isSubscribed ? (
                          <Link
                            href={`/apps/${kebabCase(app.id)}?tab=home`}
                            key={app.id}
                            passHref
                          >
                            <StyledApp>
                              {app.icon}
                              <span>{app.name}</span>
                            </StyledApp>
                          </Link>
                        ) : (
                          <StyledApp
                            key={app.id}
                            onClick={() =>
                              modal({
                                title: 'Access Unavailable',
                                text: common('info.getAppAccess'),
                                type: 'error',
                                okText: 'Contact Support',
                                cancelText: 'Cancel',
                                centered: true,
                                icon: <AccessUnavailable />,
                                onOk: () => {
                                  window.location.href = `mailto:${KOIREADER_SUPPORT_EMAIL}`;
                                },
                                onCancel: () => null,
                              })
                            }
                          >
                            {app.icon}
                            <span>{app.name}</span>
                          </StyledApp>
                        )
                      )}
                  </div>
                </StyledAppsSection>
              );
            })
          ) : (
            <Empty description="No apps available" />
          )}
        </AllAppsContainer>
        <StyledFooter>
          <div className="title">
            <span className="icon">
              <KoiVision />
            </span>
            <span>Connectivity with 10+ Platforms</span>
            <div className="accent-design"></div>
          </div>
          <div className="third-parties">
            {THIRD_PARTIES.map((thirdParty) => (
              <Image
                src={thirdParty.logoSrc}
                key={thirdParty.id}
                alt={thirdParty.id}
              />
            ))}
          </div>
        </StyledFooter>
      </StyledPage>
    </HomeContainer>
  );
};

// Main all apps page
// eslint-disable-next-line sonarjs/cognitive-complexity
const Index = (): JSX.Element => {
  // Reset in-app home filter
  React.useEffect(() => {
    homePageFiltersVar(initialFilters);
  }, []);

  // All app groups data
  const appGroups: AppGroup[] = [
    {
      id: 'smartWarehousing',
      title: AppGroupNames.smartWarehousing,
      icon: <SmartWarehousingIcon />,
      appsDetails: [
        {
          id: 'cartAudit',
          name: AppNames.cartAudit,
          icon: <CartAuditIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('cartAudit'),
        },
        {
          id: 'labelScan',
          name: AppNames.labelScan,
          icon: <LabelScanIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('labelScan'),
        },
        {
          id: 'conveyorBeltScan',
          name: AppNames.conveyorBeltScan,
          icon: <ConveyorBeltScanIcon />,
          isSubscribed:
            subscribedDashboardAppsVar().includes('conveyorBeltScan'),
        },
        {
          id: 'inventoryOptimization',
          name: AppNames.inventoryOptimization,
          icon: <InventoryOptimizationIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes(
            'inventoryOptimization'
          ),
        },
        {
          id: 'visionPicking',
          name: AppNames.visionPicking,
          icon: <VisionPickingIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('visionPicking'),
        },
        {
          id: 'itemDimensioning',
          name: AppNames.itemDimensioning,
          icon: <ItemDimensioningIcon />,
          isSubscribed:
            subscribedDashboardAppsVar().includes('itemDimensioning'),
        },
        {
          id: 'warehouseSafetySurveillance',
          name: AppNames.warehouseSafetySurveillance,
          icon: <WarehouseSafetySurveillanceIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes(
            'warehouseSafetySurveillance'
          ),
        },
        {
          id: 'dockDoorPlanning',
          name: AppNames.dockDoorPlanning,
          icon: <DockDoorPlanningIcon />,
          isSubscribed:
            subscribedDashboardAppsVar().includes('dockDoorPlanning'),
        },
      ],
    },
    {
      id: 'smartYards',
      title: AppGroupNames.smartYards,
      icon: <SmartYardsIcon />,
      appsDetails: [
        {
          id: 'yardCheck',
          name: AppNames.yardCheck,
          icon: <YardCheckIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('yardCheck'),
        },
        {
          id: 'fmcsaCompliance',
          name: AppNames.fmcsaCompliance,
          icon: <ALPRIcon />, // TODO: update
          isSubscribed:
            subscribedDashboardAppsVar().includes('fmcsaCompliance'),
        },
        {
          id: 'epod',
          name: AppNames.epod,
          icon: <EPODIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('epod'),
        },
        {
          id: 'gigo',
          name: AppNames.gigo,
          icon: <GIGOIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('gigo'),
        },
        {
          id: 'assetTracking',
          name: AppNames.assetTracking,
          icon: <AssetTrackingIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('assetTracking'),
        },
      ],
    },
    {
      id: 'smartPorts',
      title: AppGroupNames.smartPorts,
      icon: <SmartPortsIcon />,
      appsDetails: [
        {
          id: 'containerOCR',
          name: AppNames.containerOCR,
          icon: <ContainerOCRIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('containerOCR'),
        },
        {
          id: 'portSafetySurveillance',
          name: AppNames.portSafetySurveillance,
          icon: <PortSafetySurveillanceIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes(
            'portSafetySurveillance'
          ),
        },
        {
          id: 'damageDetection',
          name: AppNames.damageDetection,
          icon: <DamageDetectionIcon />, // TDO: update
          isSubscribed:
            subscribedDashboardAppsVar().includes('damageDetection'),
        },
      ],
    },
    {
      id: 'industrialAutomation',
      title: AppGroupNames.industrialAutomation,
      icon: <IndustrialAutomationIcon />,
      appsDetails: [
        {
          id: 'qrBarcodeScan',
          name: AppNames.qrBarcodeScan,
          icon: <QRBarcodeScanIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('qrBarcodeScan'),
        },
        {
          id: 'activityRecognition',
          name: AppNames.activityRecognition,
          icon: <ActivityRecognitionIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes(
            'activityRecognition'
          ),
        },
        {
          id: 'skuRecognition',
          name: AppNames.skuRecognition,
          icon: <SKURecognitionIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('skuRecognition'),
        },
      ],
    },
    {
      id: 'thirdPartyApps',
      title: AppGroupNames.thirdPartyApps,
      icon: <ThirdPartyAppsIcon />,
      appsDetails: [
        {
          id: 'realWear',
          name: AppNames.realWear,
          icon: <RealWearIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('realWear'),
        },
        {
          id: 'teamViewer',
          name: AppNames.teamViewer,
          icon: <TeamViewerIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('teamViewer'),
        },
      ],
    },
    {
      id: 'documentDigitalization',
      title: AppGroupNames.documentDigitalization,
      icon: <DocumentDigitalizationIcon />,
      appsDetails: [
        {
          id: 'deliveryOrder',
          name: AppNames.deliveryOrder,
          icon: <DeliveryOrderIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('deliveryOrder'),
        },
        {
          id: 'freightForwarding',
          name: AppNames.freightForwarding,
          icon: <FreightForwardingIcon />,
          isSubscribed:
            subscribedDashboardAppsVar().includes('freightForwarding'),
        },
      ],
    },
    {
      id: 'omniverse',
      title: AppGroupNames.omniverse,
      icon: <OmniverseIcon />,
      appsDetails: [
        {
          id: 'industrialOmniverse',
          name: AppNames.industrialOmniverse,
          icon: <IndustrialOmniverseIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes(
            'industrialOmniverse'
          ),
        },
      ],
    },
    {
      id: 'insights',
      title: AppGroupNames.insights,
      icon: <InsightsIcon />,
      appsDetails: [
        {
          id: 'analytics',
          name: AppNames.analytics,
          icon: <AnalyticsIcon />,
          isSubscribed: subscribedDashboardAppsVar().includes('analytics'),
        },
      ],
    },
  ];

  const router = useRouter();
  const changeView = (tab: string) => {
    router.push(`${router.pathname}?tab=${tab}`, undefined, {
      shallow: true,
    });
  };

  // Adding home tab consisting of all apps data initially
  const sidebarTabs = [
    {
      id: 'allApps',
      title: 'All Apps',
      icon: <HomeIcon />,
      onClick: () => changeView('allApps'),
    },
  ];

  // Appending all app groups data dynamically to sidebar props
  for (const group of appGroups) {
    sidebarTabs.push({
      id: group.id,
      title: group.title,
      icon: group.icon,
      onClick: () => changeView(group.id),
    });
  }

  const SidebarProps = {
    logo: <KoiReaderEmblem />,
    tabs: sidebarTabs,
  };

  // Choose right component based on current tab value
  const getRightComponent = (currentTab: string): JSX.Element => {
    switch (currentTab) {
      case 'allApps': {
        return <AllApps appGroups={appGroups} />;
      }

      case 'smartWarehousing': {
        const currentAppGroup = appGroups.find(
          (group) => group.id === 'smartWarehousing'
        );
        return <AllApps appGroups={[currentAppGroup as AppGroup]} />;
      }

      case 'smartYards': {
        const currentAppGroup = appGroups.find(
          (group) => group.id === 'smartYards'
        );
        return <AllApps appGroups={[currentAppGroup as AppGroup]} />;
      }

      case 'smartPorts': {
        const currentAppGroup = appGroups.find(
          (group) => group.id === 'smartPorts'
        );
        if (currentAppGroup) return <AllApps appGroups={[currentAppGroup]} />;
        break;
      }

      case 'industrialAutomation': {
        const currentAppGroup = appGroups.find(
          (group) => group.id === 'industrialAutomation'
        );
        if (currentAppGroup) return <AllApps appGroups={[currentAppGroup]} />;
        break;
      }

      case 'documentDigitalization': {
        const currentAppGroup = appGroups.find(
          (group) => group.id === 'documentDigitalization'
        );
        if (currentAppGroup) return <AllApps appGroups={[currentAppGroup]} />;
        break;
      }

      case 'thirdPartyApps': {
        const currentAppGroup = appGroups.find(
          (group) => group.id === 'thirdPartyApps'
        );
        if (currentAppGroup) return <AllApps appGroups={[currentAppGroup]} />;
        break;
      }

      case 'insights': {
        const currentAppGroup = appGroups.find(
          (group) => group.id === 'insights'
        );
        if (currentAppGroup) return <AllApps appGroups={[currentAppGroup]} />;
        break;
      }

      case 'omniverse': {
        const currentAppGroup = appGroups.find(
          (group) => group.id === 'omniverse'
        );
        if (currentAppGroup) return <AllApps appGroups={[currentAppGroup]} />;
        break;
      }

      default:
        return <AllApps appGroups={appGroups} />;
    }

    return <></>;
  };

  // Update right component when query tab updates
  const rightComponent = React.useMemo(
    () => getRightComponent(router.query.tab as string),
    [router.query.tab]
  );

  return (
    <AppLayout sidebarProps={SidebarProps}>
      <>{rightComponent}</>
    </AppLayout>
  );
};

export async function getStaticProps({ locale }: any): Promise<any> {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export default Index;
